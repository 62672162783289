<template>
  <v-sheet light color="grey lighten-4" class="pa-4 text-center">
    <h4 class="text-h6 font-weight-bold mb-2">
      Leseprobe herunterladen
    </h4>

    <p class="text-body-2 mb-6">
      Bitte wähle welche Datei du herunterladen möchtest.
    </p>

    <v-btn
      color="green white--text" class="text-body-1 mb-4 text-none font-weight-bold"
      x-large rounded block href="/documents/Leseprobe.pdf"
      target="_blank" @click="close"
    >
      PDF-Datei herunterladen
    </v-btn>

    <v-btn
      color="green white--text" class="text-body-1 text-none font-weight-bold"
      x-large rounded block href="/documents/Leseprobe.epub"
      target="_blank" @click="close"
    >
      EPUB-Datei (e-Buch) herunterladen
    </v-btn>
  </v-sheet>
</template>

<script>
export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
  },
}
</script>
